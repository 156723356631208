import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout";
import Header from "../../components/header";
import InformationPanelHeader from "../../components/information_panel_header";
import InformationPanelDetail from "../../components/information_panel_detail";
import InformationFooter from "../../components/information_footer";

type Props = {
  data: {
    microcmsPost: GatsbyTypes.MicrocmsPost;
  };
};

const InformationPage: React.FC<Props> = ({ data }) => {
  const info = data.microcmsPost;
  return (
    <>
      <Header title={info.title ?? ""} />
      <Layout>
        <InformationPanelHeader info={info} />
        <InformationPanelDetail info={info} />
        <InformationFooter />
      </Layout>
    </>
  );
};

export default InformationPage;

export const query = graphql`
  query InformationPage($id: String!) {
    microcmsPost(id: { eq: $id }) {
      id
      createdAt
      publishedAt
      updatedAt
      sortIndex
      postId
      category
      showInformation
      title
      lead
      detail
      image {
        url
        width
        height
      }
    }
  }
`;
